'use client'

import { FormObjectView, sanitiseName } from 'utility/utility'
import { ComponentFormHiddenFieldFragment } from 'generated/graphql'
import { useFormContext } from 'react-hook-form'

export const ComponentFormHiddenField: FormObjectView<ComponentFormHiddenFieldFragment> =
    ({
        name,
        value
    }) => {

        'use no memo' // eslint-disable-line react-compiler/react-compiler

        const { register } = useFormContext()

        const sanitisedName = sanitiseName(name)

        return (
            <input
                type='hidden'
                value={value}
                {...register(sanitisedName)}
            />
        )

    }
