'use client'

import { FC } from 'react'
import { strapiPublicImage } from 'utility/utility'
import { Media } from 'content-types/UploadFile/UploadFile'

import styles from './UploadFile.Video.module.scss'

export const UploadFileVideo: FC<Media & {
    showControls?: boolean,
    poster?: Media
}> = ({
    url,
    mime,
    className,
    style,
    showControls,
    poster
}) =>
    <div>
        <video
            className={`${styles.Video} ${className ?? ''}`}
            autoPlay={ showControls !== undefined ? !showControls : true}
            muted={ showControls !== undefined ? !showControls : true}
            loop={ showControls !== undefined ? !showControls : true}
            controls={ showControls ?? false }
            controlsList='nodownload'
            style={style}
            playsInline={true}
            poster={ poster !== undefined ? strapiPublicImage(poster.url) : undefined}
            onContextMenu={ (event) => event.preventDefault() }
        >
            <source src={strapiPublicImage(url)} type={mime}/>∂
        </video>
    </div>
