'use client'

import { FormObjectView, sanitiseName } from 'utility/utility'
import { ComponentFormCaptureFieldFragment } from 'generated/graphql'
import { useFormContext } from 'react-hook-form'

export const ComponentFormCaptureField: FormObjectView<ComponentFormCaptureFieldFragment> =
    ({
        name
    }) => {

        'use no memo' // eslint-disable-line react-compiler/react-compiler

        const { register } = useFormContext()

        const sanitisedName = sanitiseName(name)

        return (
            <input
                type='hidden'
                value={undefined}
                {...register(sanitisedName)}
            />
        )

    }
