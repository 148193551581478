'use client'

import { FormObjectView, key, sanitiseName } from 'utility/utility'
import { ComponentFormTextboxFieldFragment } from 'generated/graphql'
import { useFormContext } from 'react-hook-form'

export const ComponentFormTextboxField: FormObjectView<ComponentFormTextboxFieldFragment> =
    ({
        name,
        label,
        placeholder,
        required,
        ...object
    }) => {

        'use no memo' // eslint-disable-line react-compiler/react-compiler

        const { register, formState: { errors } } = useFormContext()

        const sanitisedName = sanitiseName(name)

        return (
            <>
                <label>
                    <textarea
                        id={key(object)}
                        rows={5}
                        placeholder={placeholder ?? label}
                        {...register(sanitisedName, {
                            required
                        })}
                    />
                    <span>{label}</span>
                </label>
                {
                    !required || errors[sanitisedName] === undefined ? <></> :
                        <span>This field is required</span>
                }
            </>
        )

    }
