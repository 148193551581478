'use client'

import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormObjectView, contentTypeArray } from 'utility/utility'
import { ComponentFormInterestPickerFragment } from 'generated/graphql'
import { ComponentFormInterestPickerPortfolio } from 'components/form/ComponentFormInterestPickerPortfolio/ComponentFormInterestPickerPortfolio'

import styles from './ComponentFormInterestPicker.module.scss'

export const ComponentFormInterestPicker: FormObjectView<ComponentFormInterestPickerFragment> =
    ({
        label,
        required,
        pageContext
    }) => {

        'use no memo' // eslint-disable-line react-compiler/react-compiler

        const { allPortfolios } = pageContext

        const { register, formState: { errors } } = useFormContext()

        const [ totalInterestCount, setTotalInterestCount ] = useState<number>(0)

        return (
            <fieldset className={styles.Picker}>
                <legend>{label}</legend>
                <input
                    type='hidden'
                    {...register('__internal', {
                        validate: () => !required || totalInterestCount !== 0
                    })}
                />
                <dl>
                    {
                        contentTypeArray(allPortfolios).map( portfolio =>
                            <ComponentFormInterestPickerPortfolio
                                key={`portfolio-${portfolio.slug}`}
                                totalInterestCount={totalInterestCount}
                                setTotalInterestCount={setTotalInterestCount}
                                {...portfolio}
                            />
                        )
                    }
                </dl>
                {
                    !required || errors['interests_hidden'] === undefined ? <></> :
                        <span>Please pick at least one interest</span>
                }
            </fieldset>
        )

    }
