import { ComponentUrlQueryParameterFragment } from 'generated/graphql'
import type { SiteContext } from 'context/SiteContext'
import type { ArticleContext } from 'context/ArticleContext'

export type NextUrl<T> = T & {
    queryParameters?:  ReadonlyArray<ComponentUrlQueryParameterFragment> | string
    hash?: string
    siteContext: SiteContext
    articleContext?: ArticleContext
}

export const nextUrl = (
    relativeUrl: string,
    siteUrl: string = '',
    queryParameters?: ReadonlyArray<ComponentUrlQueryParameterFragment> | string,
    hash?: string
): URL => {

    if ( typeof queryParameters === 'string' ) {
        const url = new URL(`${relativeUrl}?${queryParameters}`,siteUrl)
        url.hash = hash ?? ''
        return url
    }
    else {

        const url = new URL(relativeUrl,siteUrl)

        queryParameters?.forEach( ({key,value}) =>
            url.searchParams.append(key,value)
        )

        url.hash = hash ?? ''

        return url

    }

}
