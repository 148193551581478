'use client'

import { FormObjectView } from 'utility/utility'
import { contentType } from 'utility/utility'
import { ComponentFormFieldsetFragment } from 'generated/graphql'
import { FieldsetFieldset } from 'content-types/Fieldset/Fieldset.Fieldset/Fieldset.Fieldset'

export const ComponentFormFieldset: FormObjectView<ComponentFormFieldsetFragment> =
    ({
        fieldset,
        siteContext,
        pageContext,
        articleContext
    }) => {

        'use no memo' // eslint-disable-line react-compiler/react-compiler

        const entity = contentType(fieldset)
        const id = fieldset.data?.id

        return (
            entity === null ? <></> :
                <FieldsetFieldset
                    __typename='Fieldset'
                    siteContext={siteContext}
                    pageContext={pageContext}
                    articleContext={articleContext}
                    id={id ?? ''}
                    {...entity}
                />
        )

    }
