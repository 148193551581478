import { type DetailedHTMLProps, type AnchorHTMLAttributes } from 'react'
import { Link } from 'components/shared/Link'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { uriTransformer } from 'components/shared/Markdown/uriTransformer'

import { ContentTypeView, strapiPublicImage } from 'utility/utility'

import { ActivityLink } from 'content-types/Activity/Activity.Link/Activity.Link'

import { ArticleLink } from 'content-types/Article/Article.Link/Article.Link'
import { DatabaseLink } from 'content-types/Database/Database.Link/Database.Link'
import { DatasetLink } from 'content-types/Dataset/Dataset.Link/Dataset.Link'
import { EventSeriesLink } from 'content-types/EventSeries/EventSeries.Link/EventSeries.Link'
import { FormModal } from 'content-types/Form/Form.Modal/Form.Modal'
import { HomeLink } from 'content-types/Home/Home.Link/Home.Link'
import { InsightReportLink } from 'content-types/InsightReport/InsightReport.Link/InsightReport.Link'
import { MagazineLink } from 'content-types/Magazine/Magazine.Link/Magazine.Link'
import { MarketReportLink } from 'content-types/MarketReport/MarketReport.Link/MarketReport.Link'
import { PageIdLink } from 'content-types/Page/Page.IdLink/Page.IdLink'
import { MagazinePageIdLink } from 'content-types/MagazinePage/MagazinePage.IdLink/MagazinePage.IdLink'
import { EventSeriesPageIdLink } from 'content-types/EventSeriesPage/EventSeriesPage.IdLink/EventSeriesPage.IdLink'
import { PortfolioLink } from 'content-types/Portfolio/Portfolio.Link/Portfolio.Link'
import { SocialMediaChannelLink } from 'content-types/SocialMediaChannel/SocialMediaChannel.Link/SocialMediaChannel.Link'
import { TopicLink } from 'content-types/Topic/Topic.Link/Topic.Link'
import { ComponentBodyLoginButton } from 'components/body/ComponentBodyLoginButton/ComponentBodyLoginButton'

// Note: Type is a hack
const MarkdownLink: ContentTypeView<Omit<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'ref'>> =
    ({href, className, children, title, pageContext, siteContext, articleContext}): JSX.Element => {

        const { allForms, allChannels } = pageContext

        if ( href === undefined || href === '' ) {
            return <a className={className}>{children}</a>
        }

        const [scheme,path=''] = href.replace("%C2%A3", "£").split(':')

        let [slug, queryParameters, hash, override, extras]: string|undefined[] = []
        const pattern = /[#?£]/
        const match = path.match(pattern)
        if (match) {
            slug = path.slice(0, match.index)
            extras = path.slice(match.index)
            if (extras) {
                let remainingString = extras
                const obj: {[key:string]:string} = {}

                while (remainingString) {
                    const match = remainingString.match(pattern)
                    if (!match) break

                    const symbol = match[0]
                    const index = remainingString.indexOf(symbol)

                    const nextMatchIndex = remainingString.slice(index + 1).search(pattern)

                    if (nextMatchIndex !== -1) {
                        const nextIndex = index + 1 + nextMatchIndex
                        obj[symbol] = remainingString.substring(index + 1, nextIndex)
                        remainingString = remainingString.substring(nextIndex)
                    } else {
                        obj[symbol] = remainingString.substring(index + 1)
                        remainingString = ''
                    }
                }

                queryParameters = obj['?']
                hash = obj['#']
                override = obj['£']
                href = href.replace(`%C2%A3${override}`, "")
            }
        } else {
            slug = path
        }

        switch ( scheme ) {

            case 'activity':
                return slug === undefined ? <span>children</span> :
                    <ActivityLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        override={override}
                        siteContext={siteContext}
                    >
                        {children}
                    </ActivityLink>

            case 'article':
                return slug === undefined ? <span>children</span> :
                    <ArticleLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        override={override}
                        siteContext={siteContext}
                        articleContext={articleContext}
                    >
                        {children}
                    </ArticleLink>

            case 'database':
                return slug === undefined ? <span>children</span> :
                    <DatabaseLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        override={override}
                        siteContext={siteContext}
                    >
                        {children}
                    </DatabaseLink>

            case 'dataset':
                return slug === undefined ? <span>children</span> :
                    <DatasetLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        override={override}
                        siteContext={siteContext}
                    >
                        {children}
                    </DatasetLink>

            case 'event-series':
                return slug === undefined ? <span>children</span> :
                    <EventSeriesLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        override={override}
                        siteContext={siteContext}
                    >
                        {children}
                    </EventSeriesLink>

            case 'event-page':
                return slug === undefined ? <span>children</span> :
                    <EventSeriesPageIdLink
                        key={href}
                        path={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        override={override}
                        siteContext={siteContext}
                        pageContext={pageContext}
                    >
                        {children}
                    </EventSeriesPageIdLink>

            case 'home':
                return slug === undefined ? <span>children</span> :
                    <HomeLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        override={override}
                        siteContext={siteContext}
                    >
                        {children}
                    </HomeLink>

            case 'insight-report':
                return slug === undefined ? <span>children</span> :
                    <InsightReportLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        override={override}
                        siteContext={siteContext}
                    >
                        {children}
                    </InsightReportLink>

            case 'magazine':
               return slug === undefined ? <span>children</span> :
                    <MagazineLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        override={override}
                        siteContext={siteContext}
                    >
                        {children}
                    </MagazineLink>

            case 'magazine-page':
                return slug === undefined ? <span>children</span> :
                    <MagazinePageIdLink
                        key={href}
                        path={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        override={override}
                        siteContext={siteContext}
                        pageContext={pageContext}
                    >
                        {children}
                    </MagazinePageIdLink>

            case 'market-report':
                return slug === undefined ? <span>children</span> :
                    <MarketReportLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        override={override}
                        siteContext={siteContext}
                    >
                        {children}
                    </MarketReportLink>

            case 'page':
                return slug === undefined ? <span>children</span> :
                    <PageIdLink
                        key={href}
                        path={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        override={override}
                        siteContext={siteContext}
                        pageContext={pageContext}
                    >
                        {children}
                    </PageIdLink>

            case 'portfolio':
                return slug === undefined ? <span>children</span> :
                    <PortfolioLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        override={override}
                        siteContext={siteContext}
                    >
                        {children}
                    </PortfolioLink>

            case 'topic':
                return slug === undefined ? <span>children</span> :
                    <TopicLink
                        key={href}
                        slug={slug}
                        title={title ?? ''}
                        queryParameters={queryParameters}
                        hash={hash}
                        override={override}
                        siteContext={siteContext}
                    >
                        {children}
                    </TopicLink>

            case 'channel': {

                const channelEntity = allChannels?.data.find( ({id}) => id === slug)
                const channel = channelEntity?.attributes

                return channel === undefined || channel === null ? <></> :
                    <SocialMediaChannelLink
                        key={href}
                        {...channel}
                        queryParameters={queryParameters}
                        siteContext={siteContext}
                    />

            }

            case 'login':
                return  <ComponentBodyLoginButton
                            key={href}
                            __typename={'ComponentBodyLoginButton'}
                            siteContext={siteContext}
                            pageContext={pageContext}
                            articleContext={articleContext}
                            hidden={null} id={''} linkText={null}
                            sectionBackground={null}
                        />

            case 'form': {

                const formEntity = allForms?.data.find( ({id}) => id === slug )
                const form = formEntity?.attributes

                return formEntity?.id === undefined || formEntity?.id === null || form === undefined || form === null ? <></> :
                    <FormModal
                        key={href}
                        id={formEntity.id}
                        siteContext={siteContext}
                        pageContext={pageContext}
                        articleContext={articleContext}
                        {...form}
                    >
                        {children}
                    </FormModal>

            }

            case 'attendee-list':
            case 'brochure':
            case 'media-kit':
            case 'newsletter-issue':
            case 'presentation-pack':
            case 'press-release':
            case 'proposal':
            case 'white-paper':
                return  <Link
                            key={href}
                            href={new URL(`/api/document/${scheme}/${slug}`,siteContext.siteUrl)}
                            data-override={override}
                            className={className}
                            target='_blank'
                            prefetch={false}
                            siteContext={siteContext}
                        >
                            {children}
                        </Link>

            case 'mailto':
            case 'tel':
                return <a
                            data-override={override}
                            key={href}
                            href={href}
                            className={className}
                        >
                            {children}
                        </a>

            case undefined:
            default:
                return  href.startsWith('http') ?
                            <a
                                data-override={override}
                                key={href}
                                href={href}
                                className={className}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {children}
                            </a>
                        :
                            <Link
                                data-override={override}
                                key={href}
                                href={new URL(href,siteContext.siteUrl)}
                                className={className}
                                replace={ href.substring(0,1) === '#' }
                                scroll={ href.substring(0,1) !== '#' }
                                siteContext={siteContext}
                            >
                                {children}
                            </Link>

        }
    }

const isAnchor = (element: JSX.Element): element is React.ReactElement<React.AnchorHTMLAttributes<HTMLAnchorElement>> =>
    element.type === 'a'

// Note: type is a hack
export const Markdown: ContentTypeView<{children: string}> =
    ({
        siteContext,
        pageContext,
        articleContext,
        children
    }) => {

        return (
            <ReactMarkdown
                key='markdown'
                rehypePlugins={[rehypeRaw]}
                urlTransform={uriTransformer}
                components={{
                    p: (props): JSX.Element => {

                        const children = props.children instanceof Array ? props.children : [props.children]

                        if ( children.length === 1 ) {

                            const element = children[0] as JSX.Element | undefined | null

                            if (
                                element !== null
                                && element !== undefined
                                && isAnchor(element)
                                && ( element.props.href?.startsWith('form:') ?? false )
                            ) {
                                return  <MarkdownLink
                                            {...(element.props)}
                                            siteContext={siteContext}
                                            pageContext={pageContext}
                                            articleContext={articleContext}
                                        />
                            }
                            else {
                                return <p {...props as Omit<DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>, 'ref'>}/>
                            }

                        }
                        else {
                            return <p {...props as Omit<DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>, 'ref'>}/>
                        }
                    },
                    a: (props) =>
                        <MarkdownLink
                            {...props as Omit<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'ref'>}
                            siteContext={siteContext}
                            pageContext={pageContext}
                            articleContext={articleContext}
                        />,
                    img: ({src,alt,...img}) =>
                        <picture>
                            <img
                                {...img as Omit<DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'ref'>}
                                src={ src === undefined ? '': strapiPublicImage(src) }
                                alt={ alt === undefined ? '' : alt }
                            />
                        </picture>
                }}
            >{children}</ReactMarkdown>
        )

    }
