import { ArticleUrlFragment } from 'generated/graphql'
import type { NextUrl } from 'utility/nextUrl'
import { nextUrl } from 'utility/nextUrl'

export const ArticleUrl =
    ({
        slug,
        siteContext,
        articleContext,
        queryParameters,
        hash
    }: NextUrl<ArticleUrlFragment>): URL =>
        siteContext.site === 'events' ?
            nextUrl(
                `/${articleContext?.eventSeries?.data?.attributes?.slug ?? '' }/press/news/${slug}`,
                siteContext.siteUrlEvents,
                queryParameters,
                hash
            )
        :
            nextUrl(
                `/insights/${slug}`,
                siteContext.siteUrlMain,
                queryParameters,
                hash
            )
